.form {
    display: flex;
    flex-direction: column;
  }
  
  .regionDropdown {
    border-style: solid;
    padding: 6px;
    font-size: 16px;
    color: rgb(62, 62, 62);
    border-width: 2px;
    border-radius: 6px;
    border-color: rgb(233, 237, 244);
  }
  