@use '../../Styles/sizes' as sizes;

.component {
  display: flex;
  gap: 1rem;
}

.componentWithActionButton {
  grid-template-columns: 1fr 2fr 1fr;
}

.imageContainer {
  display: flex;
  align-items: center;
  flex: 1;

  & > .image {
    width: 100%;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: sizes.$xxs;
}

.actionContainer {
  display: flex;
  flex-direction: column;
  gap: sizes.$xxs;
}

.ant-radio-button-wrapper-checked {
  background-color: #eaf5ff !important;
}
