@use '../../Styles/sizes' as sizes;

.component {
  & > *:not(:last-child) {
    margin-bottom: sizes.$md;
  }
}

.componentSm {
  & > *:not(:last-child) {
    margin-bottom: sizes.$sm;
  }
}

.componentXs {
  & > *:not(:last-child) {
    margin-bottom: sizes.$xs;
  }
}

.componentLg {
  & > *:not(:last-child) {
    margin-bottom: sizes.$lg;
  }
}

.componentXl {
  & > *:not(:last-child) {
    margin-bottom: sizes.$xl;
  }
}

.componentXxl {
  & > *:not(:last-child) {
    margin-bottom: sizes.$xxl;
  }
}
