@use '../../Styles/breakpoints.scss' as breakpoints;
@use '../../Styles/sizes.scss' as sizes;

.component {
  display: grid;
  grid-column-gap: sizes.$md;
  grid-auto-rows: max-content;
  
  @media (min-width: breakpoints.$md) {
  //  grid-template-rows: auto 1fr; 
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: breakpoints.$lg) {

    grid-template-columns: repeat(3, 1fr);
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  button {
    margin: 8px 0;

    &:first-child {
      margin-right: 8px;
    }
  }
}

.ant-radio-button-wrapper-checked {
  background-color: #eaf5ff !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #333 !important;
  border-color: #333 !important;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #999 !important;
  border-color: #999 !important;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #333 !important;
}
