@use '../../Styles/sizes.scss' as sizes;

.form {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  flex-direction: column;
  gap: sizes.$xxs;
}

.labelHorizontal {
  flex-direction: row;
}

.input {
  border-style: solid;
  padding: 6px;
}

.select {
  border-style: solid;
  padding: 6px;
}

.horizontalRow {
  display: grid;
  gap: sizes.$md;
}
