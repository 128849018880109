@use "../../Styles/sizes" as sizes;

.component {
  display: grid;
  grid-template-columns: 1fr;
  gap: sizes.$lg;
}


.ant-radio-button-wrapper-checked {
  background-color: #eaf5ff !important;
}