body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

p {
  margin: 0;
  padding: 0;
}

button {
  padding: 0;
  margin: 0;
  background-color: none;
  border: none;
}

a {
  text-decoration: none;
}


.PhoneInputInput {
  border: #fff !important;
}


.ant-modal-close-x {
  width: 100% !important;
}

.ant-collapse-expand-icon {
  align-self: center !important;
}
h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1>textarea,
.ant-typography h1 {
  font-size: 20px !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #333333 !important;
  border-color: #333333 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #999 !important;
  border-color: #999 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #333 !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #333 !important;
}

.ant-radio {
  top:1px !important;
  }