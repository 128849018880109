@use '../../Styles/breakpoints.scss' as breakpoints;
@use '../../Styles/sizes.scss' as sizes;

.component {
  display: grid;
  gap: sizes.$md;

  @media (min-width: breakpoints.$md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: breakpoints.$lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  button {
    margin: 8px 0;

    &:first-child {
      margin-right: 8px;
    }
  }
}
.ant-radio-button-wrapper-checked {
  background-color: #eaf5ff !important;
}
