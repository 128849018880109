.component {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .containerInner {
    width: 100%;
    max-width: 1200px;
  }
}

.xs {
  & > .containerInner {
    max-width: 768px;
  }
}

.sm {
  & > .containerInner {
    max-width: 992px;
  }
}

.lg {
  & > .containerInner {
    max-width: 1400px;
  }
}
