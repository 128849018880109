@use '../../Styles/sizes.scss' as sizes;

.component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: '100%';
  margin-bottom: sizes.$md;



  & > .addToCart {
   margin-top: 3px
  }
}

.imageContainer {
  & > .image {
    width: 100%;
    border-radius:2;
  }
}


.addToCart {

  & > *:not(:last-child) {
    margin-bottom: sizes.$xs;
  }

  & > .detailsButton {
    width: 100%;
  }
}

.ant-radio-button-wrapper-checked {
  background-color: #eaf5ff !important;
}
