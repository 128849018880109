@use '../../Styles/sizes' as sizes;
@use '../../Styles/fontSizes' as fontSizes;

.component {
  padding: sizes.$md;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.branding {
  text-decoration: none;
  font-size: fontSizes.$xl;
}

.navList {
  display: flex;
  gap: sizes.$md;
  align-items: center;
}

.branding {
  text-decoration: none;
  font-size: fontSizes.$xl;
}

.component {
  display: flex;
  justify-content: center;
  & > *:not(:last-child) {
    margin-right: sizes.$xl;
  }
}

.menuItem {
  text-decoration: none;
  font-size: fontSizes.$lg;
  border: none;
  background: none;
}

.cartLink {
  & > .icon {
    padding-right: 0px;
  }
}

.languageDropdown {
  position: relative;

  &:hover {
    & > .dropdownItems {
      display: block;
    }
  }

  & > .dropdownItems {
    position: absolute;
    z-index: 999;
    bottom: 0;
  }
}

.languageDropdownButton {
  border: none;
  background: none;
  cursor: pointer;
  font-size: fontSizes.$lg;
}

.dropdownItems {
  display: none;
  transform: translateY(100%);
}

.languageButton {
  border: none;
  background: none;
  font-size: fontSizes.$md;
  cursor: pointer;
  width:100px;
  display: flex;
}
.languageCurrencyButton {
  border: none;
  background: none;
  font-size: fontSizes.$md;
  cursor: pointer;
  width: 300px;
  display: flex;
}
