@use '../../Styles/sizes.scss' as sizes;

.component {
  & > .buttonContainer {
    margin-top: sizes.$md;
    width: 100%;
  }
}

.buttonContainer {
  & > .button {
    width: 100%;
  }
}

.ant-radio-button-wrapper-checked {
  background-color: #eaf5ff !important;
}
