@use '../Styles/sizes.scss' as sizes;

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > .layoutMain {
    flex: 1;
  }
}

.layoutMain {
  padding: sizes.$lg 0;
}
