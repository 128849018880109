@use '../../Styles/fontSizes.scss' as fontSizes;

.component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > svg {
    width: 48px;
  }
}

.text {
  font-size: fontSizes.$sm;
}
