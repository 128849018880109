.component {
  & > .highlightedImage {
    width: 100%;
  }
}

.highlightedImage {
  & > .image {
    width: 100%;
  }
}

.smallImages {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.button {
  border: none;
  background: none;
  cursor: pointer;

  & > .buttonImage {
    width: 100%;
  }
}
