@use '../../Styles/breakpoints.scss' as breakpoints;
@use '../../Styles/sizes.scss' as sizes;

.cartItemContent {
  display: flex;
  flex-direction: column;

  @media(min-width: breakpoints.$md) {
    flex-direction: row;
  }

  & > .imageContainer {
    @media(min-width: breakpoints.$md) {
      max-width: 25%;
    }
  }
}

.component {
  display: grid;
  gap: sizes.$md;

  @media (min-width: breakpoints.$md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: breakpoints.$lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.imageContainer {
  & > .image {
    max-width: 100%;
  }
}

.productDetails {
  display: flex;
  flex-direction: column;
}

.checkoutButtonContainer {
  display: flex;
  justify-content: flex-end;
}

tr.highlight {
  border-top: '10px solid';
  border-bottom: '10px solid';
  border-color: 'transparent';
}
