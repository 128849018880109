@use '../Styles/sizes' as sizes;

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: sizes.$xxl 0;

  & > .loader {
    width: 55px;
  }
}

.loader {
  & > * {
    width: 100%;
    height: auto;
  }
}
