@use '../../Styles/sizes.scss' as sizes;

.component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > .addToCart {
    margin-top: sizes.$lg;
  }
}

.imageContainer {
  & > .image {
    width: 100%;
  }
}

.addToCart {

  & > *:not(:last-child) {
    margin-bottom: sizes.$xs;
  }

  & > .detailsButton {
    width: 100%;
  }
}

.ant-radio-button-wrapper-checked {
  background-color: #eaf5ff !important;
}
