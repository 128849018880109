@use '../../Styles/sizes' as sizes;

.baseComponent {
  display: block;
  border-style: solid;
  cursor: pointer;
  height: 45;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
